$default-border-radius: 8px;
$default-padding: 10px;
.consumer-detail {
  margin-top: 50px;
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #5a5a5a;
  }
  .sub-title {
    font-size: 20px;
    font-weight: 500;
    color: #5a5a5a;
  }
  .main-box {
    background: #fff;
    padding: $default-padding;
    border-radius: $default-border-radius;
    .title-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #f2f2f2;

      .back {
        width: 120px;
        height: 35px;
      }
      .save {
        height: 35px;
      }
    }
  }

  .content-detail {
    margin-top: 35px;
    label {
      font-size: 18px;
      font-weight: 600;
      margin: 10px;
    }
    .data-detail {
      font-weight: 500;
      margin-left: 10px;
      color: #5a5a5a;
    }
    .address {
      margin: 10px;
      border-top: 1px solid #f2f2f2;
      padding-top: 20px;
    }
  }
}
