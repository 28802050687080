#sidebarMenu {
  .side-items {
    ul {
      margin-left: -20px;
      ul {
        margin-left: 20px;
      }
    }
  }
}
