$default-padding: 10px;
$default-border-radius: 8px;
#additional-modal {
  background: #fff;
  width: 100%;
  margin: 50px auto;
  padding: $default-padding;
  border-radius: $default-border-radius;

  .additional-modal-items {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 100%;
    overflow-y: auto;
    .category-title {
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      span {
        font-size: 18px;
        font-weight: 600;
        margin-left: 15px;
      }
      padding: 10px;
      width: 75%;
    }
    .additional-modal-item {
      border-bottom: 3px solid rgba(105, 105, 105, 0.13);
      //   border-bottom: 0.5px solid #dcdcdc;
      width: 20%;
      height: 80px;
      min-width: 250px;
      flex-grow: 1;
      margin: 10px;
      // cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      max-width: 300px;
      border: 1px solid #d1d5db;
      img {
        border-radius: 8px;
      }
      @media (max-width: 780px) {
        min-width: 200px;
        width: 100%;
        max-width: 100%;
      }
    }
    .category-items:hover {
      background-image: linear-gradient(
        to left,
        rgba(169, 169, 169, 0.2),
        rgba(169, 169, 169, 0)
      );
    }
  }
}
