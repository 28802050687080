$default-border-radius: 10px;

.orders {
  margin-top: 30px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 0;
  }
  .error {
    border: 2px solid red !important;
    color: red !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaafb6;
    border-radius: 10px;
  }
  .orders-list {
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #5a5a5a;
    }
    .section-one {
      display: flex;
      justify-content: space-between;
      .boxs {
        display: flex;
        width: 35%;
        justify-content: space-between;
        .input-box {
          display: flex;
          width: 40%;
          height: 35px;
          span {
            border-radius: 5px 0 0 5px;
            border-collapse: collapse;
            padding: 5px;
            border: 1px solid #5a5a5a;
            background: #fff;
            display: flex;
            svg {
              cursor: pointer;
            }
          }
          input {
            border: 1px solid #5a5a5a;
            border-left: none;
            max-width: 100%;
            border-radius: 0 5px 5px 0;
            padding-left: 10px;
            font-size: 13px;
            outline: none;
          }
        }
      }
      .box-buttons {
        button {
          height: 35px;
          padding: 0 10px 0 10px;
        }
      }
    }
    .section-two {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      background: transparent;

      .order-column {
        width: 33.4%;
        min-height: 480px;
        border-radius: $default-border-radius;
        border: 1px solid #f2f2f2;
        .order-feed {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          margin: 0 10px;
        }
        .order-state {
          color: #fff;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          background: #0000001a;
          padding: 10px;
        }
        .order-content {
          padding: 0 10px;
          max-height: 460px;
          overflow-y: auto;
          padding-bottom: 50px;

          .order {
            padding: 5px;
            border-radius: $default-border-radius;
            background: #fff;
            margin-top: 5px;

            .order-lines {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border-bottom: 1px solid #838383;
              margin-top: 10px;

              .order-number {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                color: #2b2b2b;
                line-height: 25px;
              }
              .order-time {
                background: #838383;
                color: #fff;
                padding: 5px;
                border-radius: $default-border-radius;
                font-size: 13px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                  margin-right: 5px;
                }
              }

              .order-column-text {
                p {
                  color: #5a5a5a;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                }

                button {
                  border: none;
                  background: transparent;
                  font-size: 12px;
                  font-weight: 700;
                  text-decoration: underline;
                }
                button:hover {
                  scale: 0.95;
                  color: #5a5a5a;
                }
              }
            }
          }
        }
      }
    }
  }
  .order-create {
    .section-one {
      display: flex;
      justify-content: space-between;
      padding: 5px 15px;

      .title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #2b2b2b;
      }
      button {
        width: 120px;
        height: 35px;
      }
    }
    .section-title {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.5px;
      border-bottom: 1px solid #838383;
    }
    .section-two {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .product-name {
        font-weight: 500;
        font-size: 16px;
      }
      .product-description {
        font-size: 11px;
      }
      .product-price {
        font-weight: 600;
      }
      .aside-left {
        width: 25%;
        padding: 5px;
        border-radius: $default-border-radius;
        min-width: 200px;
        flex-grow: 1;
        margin: 0 10px;

        .table-select {
          outline: none !important;
          width: 100%;
          border: 2px solid #f2f2f2;
          background: #fff;
          padding: 5px;
          border-radius: $default-border-radius;
        }

        .chosen-products {
          margin: 10px 0;
          height: 550px;
          display: flex;
          flex-direction: column;

          .chosen-content {
            background: #fff;
            padding: 10px;
            border-radius: $default-border-radius;
            height: 505px;

            .actions {
              height: 75px;
              padding: 5px 0 0 0;

              .sub-total {
                display: flex;
                justify-content: space-between;
                padding: 0 5px;
                span {
                  font-weight: 500;
                }
              }
              .box-buttons {
                display: flex;
                justify-content: space-between;
                margin-top: -5px;

                .add-sub-item {
                  i {
                    margin: 5px;
                    font-size: 18px;
                    cursor: pointer;
                  }
                  span {
                    margin: 0 5px;
                    font-weight: 600;
                    font-size: 20px;
                  }
                }
                button {
                  height: 25px;
                  font-size: 14px;
                }
              }
            }

            .chosen-cart {
              height: 55%;
              border-bottom: 3px solid #2b2b2b;

              .products {
                height: 80%;
                overflow-y: auto;
                padding: 5px 10px;
              }
              .box-total {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                margin-top: 5px;
                p {
                  font-size: 18px;
                  font-weight: 500;
                }
              }
              .chosen-product {
                border: 3px solid #f2f2f2;
                display: flex;
                justify-content: space-around;
                border-radius: $default-border-radius;
                margin: 5px 0;
                p {
                  i {
                    margin-right: 5px;
                    color: red;
                    cursor: pointer;
                  }
                  i:hover {
                    scale: 0.9;
                    opacity: 0.6;
                  }
                  margin: 5px auto;
                  font-size: 12px;
                }
              }
            }

            .selected-product {
              height: 105px;
              padding: 10px;
              display: flex;
              margin-top: 15px;
              justify-content: center;

              img {
                width: 85px;
                height: 85px;
                border-radius: $default-border-radius;
              }

              .selected-text {
                margin: 0 0 0 10px;
                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .section-three {
      padding: 5px 15px;

      .content-section-three {
        background: #fff;
        padding: 15px 15px;
        border-radius: $default-border-radius;
        display: flex;
        label {
          margin: auto 10px;
          input {
            height: 30px;
            border-radius: $default-border-radius;
            outline: none;
            border: 1px solid #5a5a5a;
            margin: 0 10px;
            width: 100px;
            padding: 10px;
          }
        }
      }
    }
    .section-four {
      padding: 5px 15px;
      .content-section-four {
        background: #fff;
        padding: 15px 15px;
        border-radius: $default-border-radius;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          margin: auto 0;
          input {
            height: 30px;
            border-radius: $default-border-radius;
            outline: none;
            border: 1px solid #5a5a5a;
            margin: 10px;
            width: 100px;
            padding: 10px;
          }
        }
        .reference {
          width: 100%;
          display: flex;
          margin: 10px 0;

          textarea {
            margin: 10px;
            width: 85%;
            padding: 10px;
            outline: none;
            border-radius: $default-border-radius;
          }
        }
      }
    }
    .aside-right {
      border-radius: $default-border-radius;
      width: 70%;
      min-width: 250px;
      padding: 5px;
      flex-grow: 1;
      margin: 0 10px;

      .active-category {
        border-color: #008000 !important;
        p {
          border-bottom: 3px solid #008000 !important;
        }
      }
      .active-product {
        border-color: #008000 !important;
      }
      .section-title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.5px;
        border-bottom: 1px solid #838383;
      }

      .section-content {
        background: #fff;
        padding: 10px;
        border-radius: $default-border-radius;
        height: 550px;
        overflow-y: auto;

        .section-categories {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          height: 50%;
          overflow-y: auto;
          border-bottom: 3px solid #2b2b2b;
          .section-item {
            width: 100px;
            height: 100px;
            border: 3px solid #f2f2f2;
            border-radius: $default-border-radius;
            margin: 5px;
            padding: 5px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            p {
              font-size: 12px;
              font-weight: 600;
              text-align: center;
              border-bottom: 3px solid #f2f2f2;
              padding-bottom: 2px;
              white-space: nowrap;
            }

            img {
              height: 50px;
              width: 50px;
              max-width: 100%;
              border-radius: $default-border-radius;
              margin: 0 auto;
              margin-top: -7px;
            }
          }
          .section-item:hover {
            transition: 1s all ease;
            scale: 0.9;
            border-color: #008000;
            p {
              border-bottom: 3px solid #008000;
              transition: 1s all ease;
            }
          }
        }

        .section-products {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          height: 50%;
          overflow-y: auto;

          .section-product {
            max-width: 225px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 3px solid #f2f2f2;
            padding: 10px;
            border-radius: $default-border-radius;
            margin: 10px;
            flex-grow: 1;
            height: 130px;
            cursor: pointer;

            @media (max-width: 992px) {
              max-width: 100%;
            }

            .product-text {
              p {
                font-size: 12px;
                text-align: center;
              }
            }
            img {
              width: 100px;
              height: 100px;
              margin: auto 5px;
              border-radius: $default-border-radius;
            }
          }
          .section-product:hover {
            transition: 1s all ease;
            scale: 0.9;
            border-color: #008000;
          }
        }
      }
    }
  }
  .finalize-order {
    border-radius: $default-border-radius;
    margin: 10px 0;
    padding: 0 15px;
    button {
      height: 100% !important;
      width: 100%;
      padding: 15px;
    }
  }
  .value-information {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    flex-wrap: wrap;

    p {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .value-total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    border-top: 1px solid #777;
  }
}
