$default-padding: 10px;
$default-border-radius: 10px;

.close-order {
  .title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #2b2b2b;
  }
  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
  }
  .section-two {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ::-webkit-scrollbar {
      width: 8px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #aaafb6;
      border-radius: 10px;
    }
    .aside-left {
      width: 60%;
      flex-grow: 1;
      min-width: 250px;
      overflow-x: auto;
      padding: $default-padding;
      border-radius: $default-border-radius;
      margin: 10px;

      .input-box {
        display: flex;
        justify-content: space-between;
      }
      background: #fff;

      .order-box {
        margin-top: 10px;
        border-radius: $default-border-radius;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        p {
          font-weight: 500;
        }
      }
      table {
        margin-top: 10px;
        padding: $default-padding;
        td {
          text-align: center;
        }
      }
    }
    .aside-right {
      width: 35%;
      background: #fff;
      min-width: 250px;
      flex-grow: 1;
      padding: $default-padding;
      margin: 10px;
      border-radius: $default-border-radius;
      .discount {
        font-size: 18px;
      }
      td,
      th {
        text-align: center;
      }
      .details {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
  .section-three {
    padding: $default-padding;
    .save {
      height: 45px;
    }
  }
  .save {
    height: 35px;
    width: 100px;
  }
}
