#box-mui-information {
  outline: none;

  .box-content {
    width: 90%;
    background: #fff;
    max-width: 680px;
    height: 570px;
    margin: 50px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    .sub-title {
      font-size: 20px;
      font-weight: bold;
    }
    .highlight {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .max-width-hours {
      max-width: 200px;
    }
    .information-text {
      font-size: 15px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-top: -15px;
    }

    .information-modal {
      padding: 25px;
      outline: none;
      color: #777;
      max-height: 82%;
      overflow-y: auto;
      overflow-x: hidden;

      .box-items {
        margin-bottom: 25px;
        .text-with-background {
          border: 2px solid #777;
          padding: 5px;
          margin: 5px;
          border-radius: 4px;
          i {
            margin-right: 5px;
          }
        }
      }

      .box-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 3px solid rgba(105, 105, 105, 0.13);

        p {
          letter-spacing: 1px;
          font-style: normal;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: bolder;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 15px;
          text-align: center;
          line-height: 0.9;
        }
        i {
          font-size: 32px;
          cursor: pointer;
        }
        i:hover {
          scale: 1.2;
          transition: 1s all ease;
        }
      }
      .operation {
        margin-top: 15px;
      }
    }
    .end-modal {
      margin-top: 40px;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      button {
        border: none;
        background: #fff;
        color: #777;
        font-weight: 500;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-top: 3px solid rgba(105, 105, 105, 0.13);
        width: 100%;
        height: 100%;
        font-size: 20px;
      }
      button:hover {
        scale: 0.95;
        font-weight: 700;
      }
    }
  }
}
#box-mui-select {
  outline: none;
  .select-modal {
    width: 90%;
    background: #fff;
    max-width: 680px;
    height: 650px;
    margin: 10px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    padding: 25px;

    ::-webkit-scrollbar {
      width: 5px;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #aaafb6;
      border-radius: 10px;
    }

    .back-products {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      padding: 10px;
      background: #3d3d3d;
      color: #fff;
      width: 100%;
      border: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      span {
        font-size: 18px;
        font-weight: 600;
        margin: auto 20px;
      }
    }
    .back-products:hover {
      background: #fff;
      color: #3d3d3d;
    }

    .select-content {
      margin-top: 25px;
      border-radius: 5px;
      height: 480px;
      max-height: 480px;
      overflow-y: auto;
      padding: 0 10px 20px 10px;

      .observation {
        margin-top: 15px;
        .sub-title {
          background: #36454f;
          color: #fff;
          padding: 10px;
          border-radius: 5px;
          text-align: center;
          font-weight: 600;
          font-size: 18px;
        }
        textarea {
          width: 100%;
          border: 5px;
          padding: 10px;
          border: 1px solid #777;
        }
        textarea:focus {
          outline: none;
        }
      }
      .description {
        color: #36454f;
        font-size: 16px;
        font-weight: lighter;
        letter-spacing: 0.1px;
        line-height: 1.4;
        margin: 15px;
        text-align: center;
      }

      .box-image {
        display: flex;
        flex-direction: column;
        p {
          font-weight: 500;
          font-size: 20px;
          margin: 10px auto;
        }
        img {
          border-radius: 5px;
          margin: 0 auto;
        }
      }
      .additionals {
        padding: 10px;
        border: 1px solid #777;
        border-radius: 8px;

        .additional {
          margin: 10px 0;
          border-radius: 8px;

          .sub-title--additional {
            background: #777;
            color: #fff;
            padding: 7px 7px 7px 15px;
            border-radius: 8px;
          }

          .additional-item {
            border-bottom: 2px solid #f2f2f2;
            text-align: center;
            padding: 10px 15px 10px 15px;
            display: flex;
            justify-content: space-between;
            .item-information {
              .item-information-title,
              .item-information-price {
                font-weight: bold;
              }
              .item-information-title {
                font-weight: bold;
              }
              .item-information-price {
                font-weight: bold;
                color: #008000;
              }
            }

            .item-action {
              .amount {
                i {
                  font-size: 14px;
                  margin: 5px;
                  color: #008000;
                }
                span {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    .add-product {
      height: 60px;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      .amount {
        border: 2px solid #3d3d3d;
        border-radius: 5px;
        width: 30%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;
      }
      i {
        cursor: pointer;
      }
      .add-button {
        flex-grow: 1;
        border-radius: 5px;
        background: #3d3d3d;
        color: #fff;
        border: none;
        margin-left: 15px;
      }
      .add-button:hover {
        background: #fff;
        color: #3d3d3d;
        border: 2px solid #3d3d3d;
      }
    }
  }
}
#box-mui-cart {
  outline: none;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaafb6;
    border-radius: 10px;
  }
  .cart-modal {
    background: #fff;
    height: 100% !important;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    padding: 25px;

    @media (max-width: 992px) {
      padding: 5px;
    }
    .cart-content {
      border: 3px dashed #d3d3d3;
      width: 100%;
      max-width: 600px;
      padding: 25px;
      border-radius: 5px;
      margin: auto;
      @media (max-width: 992px) {
        padding: 5px;
      }

      .sub-title {
        font-size: 18px;
        padding-bottom: 5px;
        border-bottom: 2px solid #f2f2f2;
      }
      .products {
        max-height: 430px;
        overflow-y: auto;
        padding-bottom: 25px;

        .has-no {
          font-size: 24px;
          @media (max-width: 410px) {
            font-size: 18px;
          }
        }
        .product {
          border: 1px dotted #d3d3d3;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 15px;
          margin-bottom: 10px;
          @media (max-width: 600px) {
            padding: 10px;
            margin-left: 5px;
            margin-right: 5px;
          }
          .first-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 85%;

            .info-product {
              display: flex;
              flex-wrap: wrap;
              .info-product-additional {
                width: 100%;
                font-size: 12px;
                margin-left: 30px;
              }
              .info-product-additional-subtotal {
                margin-top: 25px;
                width: 70%;
                display: flex;
                justify-content: space-between;
              }
              @media (max-width: 600px) {
                letter-spacing: 0;
                font-size: 13.5px;
              }
              span {
                white-space: nowrap;
              }
            }
          }
          .cart-observation {
            font-size: 13px;
            margin-top: 10px;
            color: #3d3d3d;
            max-width: 100%;
            width: 80%;
            overflow-x: auto;
            span {
              white-space: pre-wrap;
            }
            p {
              font-size: 13px;
              margin-left: 13px;
              color: #777;
            }
            white-space: normal;
          }
          .second-box {
            i {
              color: #ff0000;
              font-size: 24px;
              cursor: pointer;
            }
            i:hover {
              scale: 0.9;
              color: #800000;
            }
          }
          p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.3px;

            span {
              margin: 0 5px;
            }
          }
          img {
            border-radius: 6px;
          }
        }
      }
      .box-total {
        padding: 0 10px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 16px;

          @media (max-width: 410px) {
            font-size: 14px;
          }
        }
      }

      .box-button {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        button {
          height: 45px;
          width: 46%;
          border-radius: 5px;
          @media (max-width: 992px) {
            font-size: 12px;
          }
          @media (max-width: 410px) {
            font-size: 11px;
            width: 48.5%;
          }
          @media (max-width: 380px) {
            font-size: 9px;
          }
        }
      }
    }
  }
}
#box-mui-identify {
  width: 100%;
  height: 100%;
  outline: none;
  .identify-modal {
    width: 95%;
    max-width: 350px;
    margin: 100px auto;
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    button {
      width: 100%;
      height: 45px;
      border-radius: 5px;
      margin-top: 10px;
    }
    input {
      margin-top: 5px;
      background: #f2f2f2;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: none;
      padding-left: 15px;

      font-size: 18px;
      color: #36454f;
      letter-spacing: 1px;
    }
    input:focus {
      outline: none !important;
    }
  }
}
#box-mui-info-user {
  background: #fff;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 25px;
  overflow-y: auto;
  @media (max-width: 600px) {
    padding: 5px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaafb6;
    border-radius: 10px;
  }

  .delivery-method {
    border: 2px solid #36454f;
    border-radius: 5px;

    .delivery-content {
      padding: 15px;
      border-top: 3px dashed #d2d2d2;
      border-collapse: collapse;

      .edit-dete {
        button {
          border: none;
          padding: 0;
          background: transparent;
          font-size: 13px;
          font-weight: 500;
        }
        .delete-button {
          color: #ff0000;
        }
        .edit-button {
          color: #0000ff;
        }
        button:hover {
          scale: 0.95;
          font-weight: 600;
        }
      }
      .delivery-sub-content {
        display: flex;
        justify-content: space-between;

        .first-box {
          width: 90%;
        }
        .second-box {
          width: 5%;
          input[type="radio"] {
            -webkit-appearance: none;
            background-color: #fff;
            border: 2px solid #d2d2d2;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
          input[type="radio"]:checked {
            background-color: #3d3d3d;
          }
        }
      }
      .save {
        height: 45px;
        width: 100%;

        i {
          margin-right: 10px;
        }
      }
      span {
        font-size: 13px;
        font-weight: 500;
        color: #777;
        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
      .method-sub-title {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .method-title {
      background: #36454f;
      font-size: 20px;
      text-align: center;
      padding: 5px;
      color: #fff;
      margin-bottom: 0;
      font-weight: bolder;
    }
  }

  .identify-modal {
    border: 3px dashed #d3d3d3;
    border-radius: 5px;
    width: 95%;
    max-width: 600px;
    margin: 50px auto;
    padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .destiny {
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    .identify-title {
      border-bottom: 2px solid #f2f2f2;
      text-align: center;
      padding: 5px;
      font-size: 20px;
      font-weight: 600;
      color: #36454f;
    }
    label {
      font-weight: 500;
    }
    input {
      margin-top: 5px;
      background: #f2f2f2;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: none;
      padding-left: 15px;

      font-size: 18px;
      color: #36454f;
      letter-spacing: 1px;
    }
    input:focus {
      outline: none !important;
    }
    .box-button {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      button {
        height: 45px;
        width: 48%;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }
}
#box-mui-add-address {
  outline: none;

  .address-modal {
    background: #fff;
    max-width: 350px;
    width: 95%;
    padding: 25px;
    border-radius: 5px;
    margin: 50px auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ::-webkit-scrollbar {
      width: 5px;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #aaafb6;
      border-radius: 10px;
    }
    .address-content {
      max-height: 400px;
      overflow-y: auto;
    }
    textarea {
      width: 100%;
      border-radius: 5px;
      padding: 10px;
    }
    textarea:focus {
      outline: none;
    }
    .check-box-number {
      background: #777;
      width: 20px;
      height: 20px;
      border-radius: 25%;
      margin: 5px;
    }
    label {
      font-weight: 500;
    }
    input {
      margin-top: 5px;
      background: #f2f2f2;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: none;
      padding-left: 15px;

      font-size: 18px;
      color: #36454f;
      letter-spacing: 1px;
    }
    input:focus {
      outline: none !important;
    }

    .box-button {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      button {
        height: 35px;
        width: 47%;
      }
    }
  }
}
.modal-confirm {
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
  max-width: 400px;
  margin: 200px auto;
  width: 95%;
  .modal-content {
    padding: 15px;
  }

  .title {
    color: var(--gray-900, #ff0000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: center;
    margin-top: 20px;
  }
}
.button-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
    display: flex;
    width: 146px;
    height: 35px;
    padding: 0 16px;
    justify-content: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    border: none;
    border-radius: 4px;

    span {
      margin: auto 0;
    }
  }
  .button-red {
    background: #ca433a;
    color: #fafafa;
  }
  .button-red:hover {
    background: #fafafa;
    color: #ca433a;
    border: 2px solid #ca433a;
    transition: all 0.7s ease;
  }
  .button-back {
    background: #fff;
    color: #36454f;
    border: 2px solid #36454f;
  }
  .button-back:hover {
    background: #36454f;
    color: #fff;
    transition: all 0.7s ease;
  }
}
