#menu {
  margin-top: 30px;
  .title {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
  }
  .menu-image {
    display: flex;
    flex-direction: column;
    img {
      width: 90%;
      height: 300px;
      margin: 10px auto;
    }
    input {
      margin: 10px auto;
    }
  }
  .menu-logo {
    display: flex;
    flex-direction: column;
    img {
      width: 100px;
      margin: 10px auto;
      border-radius: 50%;
    }
  }
  .menu-information {
    margin-top: 30px;
  }

  .save {
    height: 35px;
    margin: 10px 0;
  }

  .opened,
  .closed {
    height: 40px;
    width: 45%;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    border: 0;
    color: #fff;
  }

  .opened {
    background: #008000;
  }
  .closed {
    background: #b22222;
  }
  .closed:disabled,
  .opened:disabled {
    opacity: 0.5;
  }
  .opened:hover {
    color: #008000;
    background: #fff;
    border: 2px solid #008000;
    transition: 0.15s ease all;
  }
  .closed:hover {
    color: #b22222;
    background: #fff;
    border: 2px solid #b22222;
    transition: 0.15s ease all;
  }

  .status {
    text-align: center;
    font-size: 20px;
    font-size: 500;
    border: 1px solid gray;
    width: 150px;
    margin: 0 auto;
    border-radius: 25px;
  }

  .status--opened {
    color: #008000;
    background: #fff;
    border: 2px solid #008000;
    transition: 1s all ease;
  }
  .status--closed {
    color: #b22222;
    background: #fff;
    border: 2px solid #b22222;
    transition: 1s all ease;
  }

  .btn-disabled {
    background: #778899;
    border: none !important;
  }

  .address-length {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
  }
}
