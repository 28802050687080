$default-border-radius: 8px;
$default-padding: 8px;

#box-mui-consumer {
  background: #fff;
  max-width: 500px;
  width: 90%;
  border-radius: $default-border-radius;
  margin: 150px auto;
  padding: $default-padding;

  .first-section {
    display: flex;
    justify-content: space-between;
    padding: $default-padding;
    i {
      font-size: 20px;
    }
    .title-modal {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .save {
    width: 100%;
    height: 35px;
    margin-top: 15px;
  }
}
