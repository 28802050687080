.button-select {
    // style={{
    //     minHeight: 40,
    //     textAlign: "start",
    //     overflowY: "auto",
    //   }}
    min-height: 40px;
    max-width: 100%;
    padding: 10px;
    border: 2px solid #aaafb6;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;

    span {
        white-space: nowrap;
        margin: 5px !important;
    }
}