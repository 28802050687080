#box-mui-info-user {
  background: #fff;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 25px;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding: 5px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaafb6;
    border-radius: 10px;
  }

  .delivery-method {
    border: 2px solid #36454f;
    border-radius: 5px;

    .delivery-content {
      padding: 15px;
      border-top: 3px dashed #d2d2d2;
      border-collapse: collapse;

      .edit-dete {
        button {
          border: none;
          padding: 0;
          background: transparent;
          font-size: 13px;
          font-weight: 500;
        }
        .delete-button {
          color: #ff0000;
        }
        .edit-button {
          color: #0000ff;
        }
        button:hover {
          scale: 0.95;
          font-weight: 600;
        }
      }
      .delivery-sub-content {
        display: flex;
        justify-content: space-between;

        .first-box {
          width: 90%;
        }
        .second-box {
          width: 5%;
        }
      }
      .save {
        height: 45px;
        width: 100%;

        i {
          margin-right: 10px;
        }
      }
      span {
        font-size: 13px;
        font-weight: 500;
        color: #777;
        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
    .method-title {
      background: #36454f;
      font-size: 20px;
      text-align: center;
      padding: 5px;
      color: #fff;
      margin-bottom: 0;
      font-weight: bolder;
    }
  }

  .identify-modal {
    border: 3px dashed #d3d3d3;
    border-radius: 5px;
    width: 95%;
    max-width: 600px;
    margin: 50px auto;
    padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .destiny {
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    .identify-title {
      border-bottom: 2px solid #f2f2f2;
      text-align: center;
      padding: 5px;
      font-size: 20px;
      font-weight: 600;
      color: #36454f;
    }
    label {
      font-weight: 500;
    }
    input {
      margin-top: 5px;
      background: #f2f2f2;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: none;
      padding-left: 15px;

      font-size: 18px;
      color: #36454f;
      letter-spacing: 1px;
    }
    input:focus {
      outline: none !important;
    }
    .box-button {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      button {
        height: 45px;
        width: 48%;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }

  .value-information {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    flex-wrap: wrap;

    p {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .value-total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    border-top: 1px solid #777;
  }
  .payment-methods {
    .payment-method-description {
      @media (max-width: 400px) {
        font-size: 11px;
      }
    }
  }
}
