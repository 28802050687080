$default-padding: 10px;
$default-border-radius: 8px;
.menu-order-detail {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: $default-padding;
  display: flex;

  .orders-items {
    padding: $default-padding;
    margin: 100px auto;
    border: 2px dashed #36454f;
    width: 100%;
    max-width: 500px;
    border-radius: $default-border-radius;
    height: auto;
    overflow-y: auto;
    .order-detail-itens {
      li {
        span {
          margin-right: 30%;
        }
      }
      .order-detail-item {
        span,
        strong {
          font-size: 12px;
        }
        span {
          margin-right: 10% !important;
        }
      }
    }
    .section-one {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #36454f;
      margin-bottom: 25px;

      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .back {
        width: 125px;
        height: 35px;
      }
    }
    .closed {
      background: #f2f2f2;
      color: #fff;
      padding: 3px 8px;
      border-radius: $default-border-radius;
    }
    .text-comum {
      font-size: 20px;
      font-weight: 500;
      color: #2b2b2b;
    }
    .valores {
      display: flex;
      justify-content: space-between;
      .text-comum {
        font-size: 16px;
      }
    }

    .order-detail {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #2b2b2b;
      line-height: 25px;
      display: flex;
      justify-content: space-between;

      .analyzing {
        background: #c4c4c4;
        color: #fff;
        padding: 3px 8px;
        border-radius: $default-border-radius;
      }
      .producing {
        background: #ffac31;
        color: #fff;
        padding: 3px 8px;
        border-radius: $default-border-radius;
      }
      .ready {
        background: #5aaf51;
        color: #fff;
        padding: 3px 8px;
        border-radius: $default-border-radius;
      }
    }
  }
}
