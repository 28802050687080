$default-border-radius: 8px;
$default-padding: 10px;
.consumer-modal {
  background: #fff;
  width: 90%;
  max-width: 600px;
  outline: none;
  border-radius: $default-border-radius;
  padding: $default-padding;
  margin: 100px auto;
  .first-section {
    .title-content {
      display: flex;
      justify-content: space-between;
    }

    padding: $default-padding;
    border-bottom: 3px solid #f2f2f2;
    i {
      font-size: 20px;
    }
    .title-modal {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .second-section {
    padding: $default-padding;
    max-height: 350px;
    overflow-y: auto;
    .consumer-item,
    .consumer-addres {
      border: 2px dashed #008000;
      border-radius: $default-border-radius;
      margin: 5px;
      padding: $default-padding;
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #36454f;
    }
    .consumer-addres {
      font-size: 14px;
    }
    .consumer-item:hover,
    .consumer-addres:hover {
      scale: 0.99;
      opacity: 0.85;
      background: #f2f2f2;
      border: 3px solid #008000;
      color: #000;
    }
  }
}
