$default-padding: 10px;
$default-border-radius: 8px;

.menu-orders-list {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: $default-padding;
  display: flex;

  .orders-items {
    padding: $default-padding;
    margin: 100px auto;
    border: 2px dashed #36454f;
    width: 100%;
    max-width: 500px;
    border-radius: $default-border-radius;
    height: auto;
    overflow-y: auto;

    .section-one {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #36454f;
      margin-bottom: 25px;

      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .back {
        width: 125px;
        height: 35px;
      }
    }
    .closed {
      background: #f2f2f2;
      color: #fff;
      padding: 3px 8px;
      border-radius: $default-border-radius;
    }
    .order-item {
      border: 2px solid #36454f;
      border-radius: $default-border-radius;
      margin: 10px 0;
      padding: $default-padding;

      .order-number {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #2b2b2b;
        line-height: 25px;
        display: flex;
        justify-content: space-between;

        .analyzing {
          background: #c4c4c4;
          color: #fff;
          padding: 3px 8px;
          border-radius: $default-border-radius;
        }
        .producing {
          background: #ffac31;
          color: #fff;
          padding: 3px 8px;
          border-radius: $default-border-radius;
        }
        .ready {
          background: #5aaf51;
          color: #fff;
          padding: 3px 8px;
          border-radius: $default-border-radius;
        }
      }
    }
    .order-item:hover {
      scale: 0.95;
      opacity: 0.7;
      border: 1px solid #36454f;
    }
  }
}
