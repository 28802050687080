$default-border-radius: 10px;
$default-padding: 8px;
.observation-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .title {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
  }

  main {
    margin-top: 100px;
    border-radius: $default-border-radius;
    padding: $default-padding;
    height: 250px;
    background: #fff;
    width: 250px;
    textarea {
      border-radius: $default-border-radius;
      height: 150px;
      padding: $default-padding;
      width: 100%;
      max-width: 100%;
    }

    .box-button {
      display: flex;
      justify-content: space-between;
    }
  }
}
