.order-modal {
  background: #fff;
  width: 95%;
  max-width: 600px;
  margin: 50px auto;
  outline: none;
  padding: 25px;
  border-radius: 8px;

  .box-icon {
    display: flex;
    span {
      margin: -32px -30px 0 auto;
      background: #fff;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 0.5px solid #f2f2f2;
      i {
        font-size: 20px;
      }
    }
    span:hover {
      opacity: 0.8;
      scale: 0.9;
    }
  }

  .top-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 24px;
      font-weight: 600;
    }
    i {
      cursor: pointer;
    }
    .state {
      font-size: 12px;
      background: #32cd32;
      padding: 3px 7px 3px 7px;
      color: #fff;
      font-weight: 600;
      border-radius: 8px;
      height: 25px;
      margin-left: 10px;
    }
    .order-time {
      background: #838383;
      color: #fff;
      padding: 5px;
      border-radius: 8px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-right: 5px;
      }
    }
    .box-icon {
      padding: 3px;
      margin: 0 3px;
      border: 1px solid #838383;
      height: 30px;
      border-radius: 8px;
      cursor: pointer;
      i {
        margin: auto;
        font-size: 20px;
        color: #838383;
      }
    }
    .box-icon:hover {
      scale: 0.8;
      opacity: 0.8;
    }
  }
  .address-box {
    min-height: 35px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 10px;
    p {
      margin: auto 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 10px;
      color: #838383;
    }
    .address-text {
      margin-left: 10px;
      font-size: 13px;
      font-weight: 500;
    }
    border: 1px solid #838383;
  }
  .order-items-title {
    color: #838383;
    margin: 10px 0;
    font-weight: 600;
    border-bottom: 2px solid #f2f2f2;
  }
  .item-list {
    max-height: 200px;
    overflow-y: auto;
  }
  .order-list {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    p {
      font-weight: 600;
    }

    .order-observation {
      font-weight: 400;
      font-size: 12px;
      margin-left: 40px;
    }
  }
  .box-sub-total {
    border-top: 3px solid #f2f2f2;
    padding-top: 10px;
    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }
  }
  .total-box {
    display: flex;
    justify-content: space-between;
    border: 1px solid #838383;
    border-radius: 8px;
    padding: 5px;
    p {
      margin: auto 0;
      color: #838383;
      font-weight: 650;
      font-size: 18px;
    }
  }
}
