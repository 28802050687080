::-webkit-scrollbar {
  width: 8px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #aaafb6;
  border-radius: 10px;
}
.back {
  background: none;
  color: #36454f;
  border: 2px solid #36454f;
  font-weight: 600;
  border-radius: 5px;
}
.save {
  background: #008000;
  color: #fff;
  border: none;
  font-weight: 600;
  // transition: 10.5s all ease;

  border-radius: 5px;
}
.back:hover {
  background: #3d3d3d;
  color: #fff;
}
.save:hover {
  background: #fff;
  color: #008000;
  border: 2px solid #008000;
}
.required:after {
  color: #f1416c;
  content: "*";
  font-size: inherit;
  font-weight: 700;
  padding-left: 0.25rem;
  position: relative;
}
/* Chrome e outros */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.default-section {
  border: 1.5px solid #dcdcdc;
  border-radius: 8px;
  width: 80%;
  margin: 10px auto;
  background: #fff;
  padding: 10px;
}

.w-90 {
  width: 90% !important;
}

.sub-title {
  font-size: 18px;
  font-weight: bold;
  color: #6b7280;
}

input[type="radio"] {
  -webkit-appearance: none !important;
  background-color: #fff !important;
  border: 2px solid #d2d2d2 !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
}
input[type="radio"]:checked {
  background-color: #3d3d3d !important;
}

.title-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #aaafb6;
}
i {
  font-size: 20px;
}
.title-modal {
  font-size: 20px;
  font-weight: 600;
}
